import { registerApplication, start } from "single-spa";
import Clarity from '@microsoft/clarity';
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";

import microfrontendLayout from "./microfrontend-layout.html";
import "./styles.css";
import { environment } from "./environments/environment";

function initializeClarity(): void {
  try {
    Clarity.init('prb63sj9lq');
    (window as any).clarityInstance = Clarity;
    
    console.log('deuna-root-generic | Clarity initialized in container SPA:', (window as any).clarityInstance );
  } catch (error) {
    console.error('deuna-root-generic | Error al inicializar Clarity:', error);
  }
}
initializeClarity();

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });
localStorage.setItem("RSA_PARAMETER", environment.pubKey);
applications.forEach(registerApplication);
layoutEngine.activate();
start();
